.timer {
    display: flex;
    justify-content: center;
    width: 80%;
}

.timerControls{
    display: flex;
    margin-top: 20px;
    align-items: center;
}


.timer-space{
    margin-top: 80px;
}