@import url("https://fonts.googleapis.com/css?family=Public+Sans");
* {
  box-sizing: border-box;
}

.topnav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
}

.logo-container {
  position: absolute;
  left: 10px;
  top: 10px;
}

.nav-link {
  font-size: 14px; /* Adjust the size as needed */
  color: inherit;
  text-decoration: none;
  margin: 0 10px;
}
.nav-link {
  font-size: 14px; /* Adjust the size as needed */
  color: inherit;
  text-decoration: none;
  margin: 0 10px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.hidden {
  transform: translateY(-50px);
  transition: transform 5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.mode-buttons{
  padding: 5px;
}

.mode-wrapper{
  padding: 5px;
  display: flex;
  justify-content: center;
}

.mode-wrapper a{
  text-decoration:none;
}

.logo-wrapper{
  font-size: 20px;
  font-weight: 600;
  margin-left: 10px;
}

.topnav-wrapper{
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-radius: 10px;
}

.share-wrapper{
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.level-wrapper{
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.level-header{
  font-weight: 500;
}

.level{
  font-size: 24px;
  font-weight: 700;
}

.level-description{
  text-align: center;
  font-size: 8;
}


/* @media screen and (max-width: 800px) {
  
  .navi-wrapper{
    display: none;
  }

  .spacer-nav{
    display: none;
  }

  .logo-wrapper{
    display: none;
  }

  .top-wrapper{
    display: none;
  }
  
} */