/* Ensure the entire background is 1D1D1D and remove default body margin/padding */
body, html {
  background-color: #1D1D1D;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  color: #FFFFFF;
}

.explore-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #1D1D1D; /* Updated background color */
}

.header-explore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  background-color: #1D1D1D; /* Match background color */
  flex-wrap: wrap; /* Allow wrapping */
}

.logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #FFFFFF; /* Set logo color to white */
}

.logo img {
  margin-right: 10px;
  height: 40px;
}

.menu {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 20px;
}

.menu-item, .start-button {
  text-decoration: none;
  color: #FFFFFF; /* Set menu item color to white */
  font-weight: bold;
}

.search-box {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: 1px solid #FFFFFF; /* Set border color to white */
  border-radius: 4px;
  background: #2D2D2D; /* Set background color */
  color: #FFFFFF; /* Set text color to white */
}

.spaces-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.space-item {
  cursor: pointer;
  flex: 1 1 calc(25% - 20px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
  background: #2D2D2D; /* Set background color */
}

.space-item:hover {
  transform: scale(1.05);
}

.thumbnail {
  width: 100%;
  height: auto;
  display: block;
}

.space-name {
  padding: 10px;
  text-align: center;
  background: #2D2D2D; /* Set background color */
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF; /* Set text color to white */
}

.footer {
  text-align: center;
  padding: 20px 0;
  background-color: #1D1D1D; /* Match background color */
  color: #FFFFFF; /* Set footer text color to white */
  margin-top: 50px;
}
