.landing-page {
    font-family: Arial, sans-serif;
    color: #FFFFFF; /* Set text color to white */
    background: #1D1D1D; /* Set background color */
    min-height: 100vh;
    padding: 0 20px; /* Add padding for mobile */
    box-sizing: border-box;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background-color: #1D1D1D; /* Match background color */
    flex-wrap: wrap; /* Allow wrapping */
  }
  
  .logo {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF; /* Set logo color to white */
  }
  
  .logo img {
    margin-right: 10px;
    height: 40px;
  }
  
  .menu {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .menu-item, .start-button {
    text-decoration: none;
    color: #FFFFFF; /* Set menu item color to white */
    font-weight: bold;
  }
  
  .main-content {
    text-align: center;
    padding: 50px 0;
  }
  
  .main-content h1, .main-content h2 {
    color: #FFFFFF; /* Set header color to white */
  }
  
  .main-content p {
    font-size: 18px;
    margin-bottom: 30px;
    color: #FFFFFF; /* Set paragraph color to white */
  }
  
  .main-content img {
    max-width: 50%;
    height: auto; /* Make images responsive */
    border-radius: 8px;
    margin-bottom: 30px;
  }
  
  .start-button {
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 16px;
    border: 2px solid #FD2870;
    background: linear-gradient(90deg, #FD2870 0%, #D63B6F 100%);
    box-shadow: 0px 8px 30px 0px rgba(253, 40, 112, 0.50);
    color: #FFFFFF; /* Set button text color to white */
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .start-button:hover {
    transform: scale(1.05); /* Slightly enlarge button on hover */
  }
  
  .footer {
    text-align: center;
    padding: 20px 0;
    background-color: #1D1D1D; /* Match background color */
    color: #FFFFFF; /* Set footer text color to white */
    margin-top: 50px;
  }
  
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .menu {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
  
    .main-content {
      padding: 30px 0;
    }
  }
  