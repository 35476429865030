.bottom-nav{
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translate(-50%, 0);
  height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 10px;
  z-index: 1;
}

.top-wrapper{
  display: flex;
  align-items: center;
}

.spotify-logo{
  animation-name: spin;
  animation-duration: 6000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

.spotify-header{
  font-size: 12px;
  font-weight: 500;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

/* COLOR MODE */
.bottom-nav a:hover {
  background-color: #292929;
  border-radius: 10px;
}
.bottom-nav.dark a:hover {
  background-color: #292929;
}
.bottom-nav.neon a:hover {
  background-color: #292929;
}
.bottom-nav.darkBlue a:hover {
  background-color: #292929;
}
.bottom-nav.darkRed a:hover {
  background-color: #292929;
}

.bottom-nav.light a:hover {
  background-color: #f0f0ee;
}
.bottom-nav.tan a:hover {
  background-color: #e0dcd8;
}
.bottom-nav.lightBlue a:hover {
  background-color: #e7e7e7;
}
.bottom-nav.lightRed a:hover {
  background-color: #dfd4d4;
}

.spotify-wrapper{
  /* margin-left: -100px;
  margin-top: -35px; */
}

.bottom-nav a{
  text-decoration: none;
  margin-left: 7px;
}

/* COLOR MODE */
.bottom-nav.dark{
  background-color: #1f1f1f;
}
.bottom-nav.neon{
  background-color: #1f1f1f;
}
.bottom-nav.darkBlue{
  background-color: #1f1f1f;
}
.bottom-nav.darkRed{
  background-color: #1f1f1f;
}

.bottom-nav.light{
  background-color: #F7F7F5;
}
.bottom-nav.tan{
  background-color: #EDE8E1;
}
.bottom-nav.lightBlue{
  background-color: #c8d1e3;
}
.bottom-nav.lightRed{
  background-color: #dfc7cb;
}

.bottom-wrapper{
  align-items: center;
  display: flex;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 350px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transform: translateX(350px);
  transition: transform 0.3s ease-out;
}

.sidebar--open {
  transform: translateX(0);
}

.sidebar--closed {
  transform: translateX(350px);
}

.center-align {
  text-align: center;
  padding: 20px;
}

.title {
  text-align: center;
  font-size: 20px;
  padding: 15px;
}

.sub-title {
  text-align: center;
  font-size: 16px;
  padding: 10px;
}

#close:hover,
#close:active,
#close:focus {
  background: white;
  color: black;
}

.close-btn:hover {
  color: #fafafa;
}

@media screen and (max-width: 800px) {
  .bottom-nav{
    display: none !important;
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translate(-50%, 0);
  height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 10px;
  z-index: 1;
}
  }

