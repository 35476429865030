.timer-wrapper{
    display: flex;
}

/* COLOR MODE */
.icon-wrapper{
    height: 20px;
    margin-top: 4px;
    cursor: pointer;
}

.icon-wrapper-small{
    height: 16px;
    margin-top: 4px;
    cursor: pointer;
}



/* COLOR MODE */
.startPauseButton{
    background-color: #f7f7f5;
    padding: 10px 40px;
    border: none;
    border-radius: 5px;
    cursor:pointer;
    margin-left: 28px ;
    font-weight: 500;
}
.startPauseButton.dark{
    background-color: #1f1f1f;
    padding: 10px 40px;
    border: none;
    border-radius: 5px
}
.startPauseButton.neon{
    background-color: #1f1f1f;
    padding: 10px 40px;
    border: none;
    border-radius: 5px
}
.startPauseButton.darkBlue{
    background-color: #1f1f1f;
    padding: 10px 40px;
    border: none;
    border-radius: 5px
}
.startPauseButton.darkRed{
    background-color: #1f1f1f;
    padding: 10px 40px;
    border: none;
    border-radius: 5px
}


.startPauseButton.light{
    background-color: #f7f7f5;
    padding: 10px 40px;
    border: none;
    border-radius: 5px
}
.startPauseButton.tan{
    background-color: #EDE8E1;
    padding: 10px 40px;
    border: none;
    border-radius: 5px
}
.startPauseButton.lightBlue{
    background-color: #f7f7f5;
    padding: 10px 40px;
    border: none;
    border-radius: 5px
}
.startPauseButton.lightRed{
    background-color: #f7f7f5;
    padding: 10px 40px;
    border: none;
    border-radius: 5px
}

.timerInput-wrapper{
    display: flex;
    flex-direction: column;
    width: 100px;
    margin-left: 10px;
}

/* COLOR MODE */
.currentTimer{
    background-color: #f7f7f5;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
}
.currentTimer.dark{
    background-color: #1f1f1f;
    padding: 10px;
    border-radius: 5px
}
.currentTimer.neon{
    background-color: #1f1f1f;
    padding: 10px;
    border-radius: 5px
}
.currentTimer.darkBlue{
    background-color: #1f1f1f;
    padding: 10px;
    border-radius: 5px
}
.currentTimer.darkRed{
    background-color: #1f1f1f;
    padding: 10px;
    border-radius: 5px
}

.currentTimer.light{
    background-color: #f7f7f5;
    padding: 10px;
    border-radius: 5px
}
.currentTimer.tan{
    background-color: #EDE8E1;
    padding: 10px;
    border-radius: 5px
}
.currentTimer.lightBlue{
    background-color: #f7f7f5;
    padding: 10px;
    border-radius: 5px
}
.currentTimer.lightRed{
    background-color: #f7f7f5;
    padding: 10px;
    border-radius: 5px
}

.type-wrapper{
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    margin-left: 20px;
    font-weight: 500;
}

.control-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timerButtons {
    display: flex;
    align-items: center;
}

.modal-header{
    margin: 10px 10px;
    font-size: 18px;
    font-weight: 700;
}