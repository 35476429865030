.header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.search-box {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.volume-controls {
    display: flex;
    align-items: center;
}

.volume-controls .mute-button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
}

.volume-controls .mute-button img {
    width: 24px; /* Adjust size as needed */
    height: 24px;
}

.volume-controls .volume-slider {
    flex-grow: 1;
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: #1D1D1D;
    border-radius: 5px;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.volume-controls .volume-slider:hover {
    opacity: 1;
}

.volume-controls .volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: #FFF;
    border-radius: 50%;
    cursor: pointer;
}

.volume-controls .volume-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #FFF;
    border-radius: 50%;
    cursor: pointer;
}

.spacerSide {
    width: 100%;
    background-color: #eaeaea;
    height: fit-content;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.shuffle-button {
    padding: 10px;
    font-size: 16px;
    border: none;
    background-color: #1D1D1D;
    color: #FFF;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    margin-bottom: 10px;
}

.shuffle-button:hover {
    background-color: #2f2f2f;
}

.urls-list {
    max-height: calc(100% - 250px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 10px;
}

.url-button {
    width: calc(50% - 10px); /* Two columns with 10px gap */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-bottom: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
}

.url-button:hover {
    background-color: #e0e0e0;
}

.thumbnail {
    margin-bottom: 10px;
    border-radius: 5px;
    width: 100%;
}

.video-title {
    flex: 1;
    font-size: 14px;
    text-align: center;
}
