@import url("https://fonts.googleapis.com/css?family=Public+Sans");
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.spacer {
  margin-left: 10px;
}

.spacer-nav {
  margin-left: 10px;
}

.spacer2 {
  margin-left: 20px;
}

.afterdark{
  background: transparent;
  border: none !important;
  /* font-size:0; */
}

/* For the button selectors */
/* COLOR MODE */
.dark-select {
  background-color: #000000;
  border: 2px solid #FFFFFF;
  width: 30px;
  height: 30px;
  border-radius: 10px;
}
.neon-select {
  background-color: #F0FF42;
  border: 2px solid #FFFFFF;
  width: 30px;
  height: 30px;
  border-radius: 10px;
}
.darkBlue-select {
  background-color: #00F5FF;
  border: 2px solid #FFFFFF;
  width: 30px;
  height: 30px;
  border-radius: 10px;
}
.darkRed-select {
  background-color: #EA047E;
  border: 2px solid #FFFFFF;
  width: 30px;
  height: 30px;
  border-radius: 10px;
}
.light-select {
  background-color: #FFFFFF;
  border: 2px solid black;
  width: 30px;
  height: 30px;
  border-radius: 10px;
}
.tan-select {
  background-color: #F4F3EE;
  border: 2px solid black;
  width: 30px;
  height: 30px;
  border-radius: 10px;
}
.lightBlue-select {
  background-color: #d4dae6;
  border: 2px solid black;
  width: 30px;
  height: 30px;
  border-radius: 10px;
}
.lightRed-select {
  background-color: #f0dee1;
  border: 2px solid black;
  width: 30px;
  height: 30px;
  border-radius: 10px;
}

/* COLOR MODE */
.dark {
  background-color: #000000;
  color: #E9E9E9;
}
.neon {
  background-color: #000000;
  color: #F0FF42;
}
.darkBlue {
  background-color: #000000;
  color: #00F5FF;
}
.darkRed {
  background-color: #000000;
  color: #EA047E;
}
.light {
  background-color: #FFFFFF;
  color: black;
}
.tan {
  background-color: #F4F3EE;
  color: #2c2012;
}
.lightBlue {
  background-color: #d4dae6;
  color: black;
}
.lightRed {
  background-color: #f0dee1;
  color: black;
}
.afterdark {
  background-image: url("https://im2.ezgif.com/tmp/ezgif-2-3ee6133376.gif");
  background-color: #cccccc;
}

.ampm-wrapper{
  color: #E9E9E9;
  margin-left: -55%;
  margin-top: -23%;
  position: fixed;
  z-index: 1;
  font-size: 3.5vw;
  font-weight: 700;
}

/* COLOR MODE */
.ampm-wrapper.dark {
  color: #E9E9E9;
  background-color: #1f1f1f;
}
.ampm-wrapper.neon {
  color: #F0FF42;
  background-color: #1f1f1f;
}
.ampm-wrapper.darkBlue {
  color: #00F5FF;
  background-color: #1f1f1f;
}
.ampm-wrapper.darkRed {
  color: #EA047E;
  background-color: #1f1f1f;
}

.ampm-wrapper.light {
  color: black;
  background-color: #F7F7F5;
}
.ampm-wrapper.tan {
  color: black;
  background-color: #EDE8E1;
}
.ampm-wrapper.lightBlue {
  color: black;
  background-color: #c8d1e3;
}
.ampm-wrapper.lightRed {
  color: black;
  background-color: #dfc7cb;
}
  

.time-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;
}


.flipClock {
  display: flex;
  justify-content: center;
  width: 80%;
}

/* COLOR MODE */
.flipTimerUnitContainer {
  display: block;
  position: relative;
  width: 33%;
  height: 50%;
  perspective-origin: 50% 50%;
  perspective: 800px;
  border-radius: 10px;
}
.flipTimerUnitContainer.dark {
  background-color: #1f1f1f;
}
.flipTimerUnitContainer.neon {
  background-color: #1f1f1f;
}
.flipTimerUnitContainer.darkBlue {
  background-color: #1f1f1f;
}
.flipTimerUnitContainer.darkRed {
  background-color: #1f1f1f;
}

.flipTimerUnitContainer.light {
  background-color: #F7F7F5;
}
.flipTimerUnitContainer.tan {
  background-color: #EDE8E1;
}
.flipTimerUnitContainer.lightBlue {
  background-color: #c8d1e3;
}
.flipTimerUnitContainer.lightRed {
  background-color: #dfc7cb;
}

.flipUnitContainer {
  display: block;
  position: relative;
  width: 40%;
  height: 50%;
  perspective-origin: 50% 50%;
  perspective: 1800px;
  border-radius: 10px;
}

/* COLOR MODE */

.flipUnitContainer.dark {
  background-color: #1f1f1f;
}
.flipUnitContainer.neon {
  background-color: #1f1f1f;
}
.flipUnitContainer.darkBlue {
  background-color: #1f1f1f;
}
.flipUnitContainer.darkRed {
  background-color: #1f1f1f;
}

.flipUnitContainer.light {
  background-color: #F7F7F5;
}
.flipUnitContainer.tan {
  background-color: #EDE8E1;
}
.flipUnitContainer.lightBlue {
  background-color: #c8d1e3;
}
.flipUnitContainer.lightRed {
  background-color: #dfc7cb;
}

.upperCard,
.lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 15vw;
  overflow: hidden;
}
.upperCard span,
.lowerCard span {
  font-size: 20vw;
  font-weight: bolder;
  /* font-family: "Droid Sans Mono", monospace;
  font-weight: lighter; */
  /* color: #E9E9E9; */
}

.upperCard span,
.lowerCard span {
  font-size: 20vw;
  font-weight: bolder;
  /* font-family: "Droid Sans Mono", monospace;
  font-weight: lighter; */
}

.upperCard {
  align-items: flex-end;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* COLOR MODE */

.upperCard.dark{
  background-color: #1f1f1f;
}
.upperCard.neon{
  background-color: #1f1f1f;
}
.upperCard.darkBlue{
  background-color: #1f1f1f;
}
.upperCard.darkRed{
  background-color: #1f1f1f;
}

.upperCard.light{
  background-color: #F7F7F5;
}
.upperCard.tan{
  background-color: #EDE8E1;
}
.upperCard.lightBlue{
  background-color: #c8d1e3;
}
.upperCard.lightRed{
  background-color: #dfc7cb;
}


.upperCard span {
  transform: translateY(50%);
}

.lowerCard {
  align-items: flex-start;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* COLOR MODE */

.lowerCard.dark{
  background-color: #1f1f1f;
}
.lowerCard.neon{
  background-color: #1f1f1f;
}
.lowerCard.darkBlue{
  background-color: #1f1f1f;
}
.lowerCard.darkRed{
  background-color: #1f1f1f;
}
.lowerCard.light{
  background-color: #F7F7F5;
}
.lowerCard.tan{
  background-color: #EDE8E1;
}
.lowerCard.lightBlue{
  background-color: #c8d1e3;
}
.lowerCard.lightRed{
  background-color: #dfc7cb;
}

.lowerCard span {
  transform: translateY(-50%);
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 15vw;
  overflow: hidden;
  backface-visibility: hidden;
}
.flipCard span {
  /* font-family: "Droid Sans Mono", monospace; */
  font-size: 20vw;
  font-weight: bolder;
  /* font-weight: lighter; */
  /* color: #E9E9E9; */
}
.flipCard.unfold {
  top: 50%;
  align-items: flex-start;
  transform-origin: 50% 0%;
  transform: rotateX(180deg);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* COLOR MODE */
.flipCard.unfold.dark {
  background-color: #1f1f1f;
  border-top: 0.3vw solid #000000;
}
.flipCard.unfold.neon {
  background-color: #1f1f1f;
  border-top: 0.3vw solid #000000;
}
.flipCard.unfold.darkBlue {
  background-color: #1f1f1f;
  border-top: 0.3vw solid #000000;
}
.flipCard.unfold.darkRed {
  background-color: #1f1f1f;
  border-top: 0.3vw solid #000000;
}

.flipCard.unfold.light {
  background-color: #F7F7F5;
  border-top: 0.3vw solid #F7F7F5;
}
.flipCard.unfold.tan {
  background-color: #EDE8E1;
  border-top: 0.3vw solid #F4F3EE;
}
.flipCard.unfold.lightBlue {
  background-color: #c8d1e3;
  border-top: 0.3vw solid #d4dae6;
}
.flipCard.unfold.lightRed {
  background-color: #dfc7cb;
  border-top: 0.3vw solid #f0dee1;
}

.flipCard.unfold span {
  transform: translateY(-50%);
}
.flipCard.fold {
  top: 0%;
  align-items: flex-end;
  transform-origin: 50% 100%;
  transform: rotateX(0deg);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* COLOR MODE */

.flipCard.fold.dark {
  background-color: #1f1f1f;
  border-bottom: .3vw solid #000000;
}
.flipCard.fold.neon {
  background-color: #1f1f1f;
  border-bottom: .3vw solid #000000;
}
.flipCard.fold.darkBlue {
  background-color: #1f1f1f;
  border-bottom: .3vw solid #000000;
}
.flipCard.fold.darkRed {
  background-color: #1f1f1f;
  border-bottom: .3vw solid #000000;
}

.flipCard.fold.light {
  background-color: #F7F7F5;
  border-bottom: .3vw solid #F7F7F5;
}
.flipCard.fold.tan {
  background-color: #EDE8E1;
  border-bottom: .3vw solid #F4F3EE;
}
.flipCard.fold.lightBlue {
  background-color: #c8d1e3;
  border-bottom: .3vw solid #d4dae6;
}
.flipCard.fold.lightRed {
  background-color: #dfc7cb;
  border-bottom: .3vw solid #f0dee1;
}


.flipCard.fold span {
  transform: translateY(50%);
}

.fold {
  animation: fold 1s cubic-bezier(0.455, 0.3, 0.515, 0.955) 0s 1 normal
    forwards;
  transform-style: preserve-3d;
}

.unfold {
  animation: unfold 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal
    forwards;
  transform-style: preserve-3d;
}

@keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}
@keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}


@media screen and (max-width: 800px) {

  .time-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 0px;
  }

}


