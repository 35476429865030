/* TimeWidget.css */
.time-widget {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    z-index: 1000;
    width: 50vw; /* Adjust width to occupy half the screen */
    height: 50vh; /* Adjust height to occupy half the screen */
  }

  .time-controls{
    display: flex;
    flex-direction: flex;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
  }
  
  .time-display, .time-display-input {
    font-size: 96px; /* Increase font size for larger display */
    font-weight: bold;
    background: none;
    border: none;
    color: white;
    text-align: center;
    width: 100%;
  }
  
  .time-display-input:focus {
    outline: none;
  }
  
  .timer-container, .pomodoro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .start-pause-button, .refresh-button, .next-phase-button {
    margin-top: 20px; /* Adjust margin for larger buttons */
    padding: 10px 30px; /* Increase padding for larger buttons */
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 24px; /* Increase font size for larger buttons */
    margin-left: 10px;
    background-color: transparent;
  }
  
  .phase-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .phase-buttons button {
    background: none;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 24px; /* Increase font size for phase buttons */
  }
  
  .phase-buttons button.active {
    background-color: #555;
    border-radius: 10px;
  }
  
  .completed-pomodoros {
    margin-top: 20px;
  }
  
  .pomodoro-dot {
    font-size: 36px; /* Increase font size for completed Pomodoro dots */
    color: #ff0000;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
  }
  