.video-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
}

.react-player {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 73.5%, #000 100%);
    pointer-events: none;
    z-index: 1;
}

@media (min-aspect-ratio: 16/9) {
    .react-player {
        height: 86.25vw !important;
    }
}

@media (max-aspect-ratio: 16/9) {
    .react-player {
        width: 177.78vh !important;
        height: 120% !important;
    }
}
