@import url("https://fonts.googleapis.com/css?family=Public+Sans");

.toolbar-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  }

.toolbar {
    position: absolute;
    left:auto;
    bottom: 20px;
    height: 73px;
    width: fit-content;
    background-color: #141414;
    padding: 2px 10px;
    border-radius: 10px;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

 .level-wrapper{
  padding: 10px;
 }

  .linebreaker{
    background-color: #d3d3d3;
    height: 50%;
    width: 1px;
  }

  /* COLOR MODE */
  .toolbar.dark{
    background-color: #141414;
  }
  .toolbar.neon{
    background-color: #141414;
  }
  .toolbar.darkBlue{
    background-color: #141414;
  }
  .toolbar.darkRed{
    background-color: #141414;
  }

  .toolbar.light{
    background-color: #F7F7F5;
  }
  .toolbar.tan{
    background-color: #EDE8E1;
  }
  .toolbar.lightBlue{
    background-color: #c8d1e3;
  }
  .toolbar.lightRed{
    background-color: #dfc7cb;
  }

  .toolbar .icon{
    font-size: 24px;
    width: 30px;
  }

  .toolbar a {
    display: flex;
    text-align: center;
    padding: 5px 2px;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;
    width: 70px;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* COLOR MODE */

.toolbar.dark a {
  color: #E9E9E9;
}
.toolbar.neon a {
  color: #E9E9E9;
}
.toolbar.darkBlue a {
  color: #E9E9E9;
}
.toolbar.darkRed a {
  color: #E9E9E9;
}

.toolbar.light a {
  color: black;
}
.toolbar.tan a {
  color: #2c2012;
}
.toolbar.lightBlue a {
  color: black;
}
.toolbar.lightRed a {
  color: black;
}

.toolbar a:hover {
  background-color: #292929;
  border-radius: 10px;
}

/* COLOR MODE */
.toolbar.dark a:hover {
  background-color: #292929;
}
.toolbar.neon a:hover {
  background-color: #292929;
}
.toolbar.darkBlue a:hover {
  background-color: #292929;
}
.toolbar.darkRed a:hover {
  background-color: #292929;
}

.toolbar.light a:hover {
  background-color: #f0f0ee;
}
.toolbar.tan a:hover {
  background-color: #e0dcd8;
}
.toolbar.lightBlue a:hover {
  background-color: #e7e7e7;
}
.toolbar.lightRed a:hover {
  background-color: #dfd4d4;
}

.progress{
  opacity: .3;
}
/* 
@media screen and (max-width: 800px) {
  .toolbar {
    display: none !important;
    position: absolute;
    left:auto;
    bottom: 110px;
    height: 73px;
    width: 300px;
    background-color: #141414;
    padding: 2px 10px;
    border-radius: 10px;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
    
  } */


  .time-buttons{
    background: none;
    border: none;
    border-radius: 10px;
  }

  .time-buttons:hover{
background:#14141411;
  }

  .toolbar-icon {
    height: 50px;
    cursor: pointer;
    transition: transform 0.2s;
    font-size: 12px;
    margin-right: 5px;
  }
  
  .toolbar-icon:hover {
    transform: scale(1.1);
  }
  
  .time-controls {
    display: flex;
  }
  
  .time-buttons {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icon {
    font-size: 24px;
  }
  

  .spotify-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .playlist-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #CCC;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .playlist-button {
    padding: 10px;
    font-size: 16px;
    border: none;
    background-color: #1D1D1D;
    color: #FFF;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .playlist-button:hover {
    background-color: #1d1d1dd3;
  }
  
  .playlist-iframe {
    border: none;
    border-radius: 10px;
  }